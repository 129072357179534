import { render, staticRenderFns } from "./GameDescription.vue?vue&type=template&id=af8bdc9c&scoped=true&"
var script = {}
import style0 from "./GameDescription.vue?vue&type=style&index=0&id=af8bdc9c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af8bdc9c",
  null
  
)

export default component.exports