<template>
	<b-row
		class="d-flex align-items-center text-center room-entry"
		@click="$emit('current-room-click', currentRoomFromActiveRoomsGraph)"
	>
		<b-col cols="6" class="pr-1" v-if="displayRoomName">
			<p class="m-0 text-left room-name w-100">
				{{ activeRoomNameFromActiveRoomsGraph }}
			</p>
		</b-col>

		<b-col cols="6" style="line-height: 0">
			<svg
				class="men-img"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 149.60001 27.200001"
				width="149.60001"
				height="27.200001"
			>
				<path
					v-if="currentRoomFromActiveRoomsGraph.capacity > 7"
					d="M 4.8,0 C 5.52745,0 6.22511,0.28657 6.73949,0.796669 7.25388,1.30677 7.54286,1.99861 7.54286,2.72 7.54286,3.44139 7.25388,4.13323 6.73949,4.64333 6.22511,5.15343 5.52745,5.44 4.8,5.44 4.07255,5.44 3.37489,5.15343 2.86051,4.64333 2.34612,4.13323 2.05714,3.44139 2.05714,2.72 2.05714,1.99861 2.34612,1.30677 2.86051,0.796669 3.37489,0.28657 4.07255,0 4.8,0 Z M 2.74286,6.8 h 4.11428 c 0.72745,0 1.42511,0.28657 1.9395,0.79667 C 9.31102,8.10677 9.6,8.79861 9.6,9.52 V 17 H 7.54286 V 27.2 H 2.05714 V 17 H 0 V 9.52 C 0,8.79861 0.288979,8.10677 0.803365,7.59667 1.31775,7.08657 2.01541,6.8 2.74286,6.8 Z"
					v-bind:class="{
						'player-active': fillMan(8),
					}"
				/>
				<path
					v-if="currentRoomFromActiveRoomsGraph.capacity > 6"
					d="m 24.8,0 c 0.72745,0 1.42511,0.28657 1.93949,0.796669 0.51439,0.510101 0.80337,1.201941 0.80337,1.923331 0,0.72139 -0.28898,1.41323 -0.80337,1.92333 C 26.22511,5.15343 25.52745,5.44 24.8,5.44 24.07255,5.44 23.37489,5.15343 22.86051,4.64333 22.34612,4.13323 22.05714,3.44139 22.05714,2.72 c 0,-0.72139 0.28898,-1.41323 0.80337,-1.923331 C 23.37489,0.28657 24.07255,0 24.8,0 Z m -2.05714,6.8 h 4.11428 c 0.72745,0 1.42511,0.28657 1.9395,0.79667 C 29.31102,8.10677 29.6,8.79861 29.6,9.52 V 17 H 27.54286 V 27.2 H 22.05714 V 17 H 20 V 9.52 C 20,8.79861 20.288979,8.10677 20.803365,7.59667 21.31775,7.08657 22.01541,6.8 22.74286,6.8 Z"
					v-bind:class="{
						'player-active': fillMan(7),
					}"
				/>
				<path
					v-if="currentRoomFromActiveRoomsGraph.capacity > 5"
					d="m 44.8,0 c 0.72745,0 1.42511,0.28657 1.93949,0.796669 0.51439,0.510101 0.80337,1.201941 0.80337,1.923331 0,0.72139 -0.28898,1.41323 -0.80337,1.92333 C 46.22511,5.15343 45.52745,5.44 44.8,5.44 44.07255,5.44 43.37489,5.15343 42.86051,4.64333 42.34612,4.13323 42.05714,3.44139 42.05714,2.72 c 0,-0.72139 0.28898,-1.41323 0.80337,-1.923331 C 43.37489,0.28657 44.07255,0 44.8,0 Z m -2.05714,6.8 h 4.11428 c 0.72745,0 1.42511,0.28657 1.9395,0.79667 C 49.31102,8.10677 49.6,8.79861 49.6,9.52 V 17 H 47.54286 V 27.2 H 42.05714 V 17 H 40 V 9.52 C 40,8.79861 40.288979,8.10677 40.803365,7.59667 41.31775,7.08657 42.01541,6.8 42.74286,6.8 Z"
					v-bind:class="{
						'player-active': fillMan(6),
					}"
				/>
				<path
					v-if="currentRoomFromActiveRoomsGraph.capacity > 4"
					d="m 64.8,0 c 0.72745,0 1.42511,0.28657 1.93949,0.796669 0.51439,0.510101 0.80337,1.201941 0.80337,1.923331 0,0.72139 -0.28898,1.41323 -0.80337,1.92333 C 66.22511,5.15343 65.52745,5.44 64.8,5.44 64.07255,5.44 63.37489,5.15343 62.86051,4.64333 62.34612,4.13323 62.05714,3.44139 62.05714,2.72 c 0,-0.72139 0.28898,-1.41323 0.80337,-1.923331 C 63.37489,0.28657 64.07255,0 64.8,0 Z m -2.05714,6.8 h 4.11428 c 0.72745,0 1.42511,0.28657 1.9395,0.79667 C 69.31102,8.10677 69.6,8.79861 69.6,9.52 V 17 H 67.54286 V 27.2 H 62.05714 V 17 H 60 V 9.52 C 60,8.79861 60.288979,8.10677 60.803365,7.59667 61.31775,7.08657 62.01541,6.8 62.74286,6.8 Z"
					v-bind:class="{
						'player-active': fillMan(5),
					}"
				/>
				<path
					v-if="currentRoomFromActiveRoomsGraph.capacity > 3"
					d="m 84.8,0 c 0.72745,0 1.42511,0.28657 1.93949,0.796669 0.51439,0.510101 0.80337,1.201941 0.80337,1.923331 0,0.72139 -0.28898,1.41323 -0.80337,1.92333 C 86.22511,5.15343 85.52745,5.44 84.8,5.44 84.07255,5.44 83.37489,5.15343 82.86051,4.64333 82.34612,4.13323 82.05714,3.44139 82.05714,2.72 c 0,-0.72139 0.28898,-1.41323 0.80337,-1.923331 C 83.37489,0.28657 84.07255,0 84.8,0 Z m -2.05714,6.8 h 4.11428 c 0.72745,0 1.42511,0.28657 1.9395,0.79667 C 89.31102,8.10677 89.6,8.79861 89.6,9.52 V 17 H 87.54286 V 27.2 H 82.05714 V 17 H 80 V 9.52 C 80,8.79861 80.288979,8.10677 80.803365,7.59667 81.31775,7.08657 82.01541,6.8 82.74286,6.8 Z"
					v-bind:class="{
						'player-active': fillMan(4),
					}"
				/>
				<path
					v-if="currentRoomFromActiveRoomsGraph.capacity > 2"
					d="m 104.8,0 c 0.72745,0 1.42511,0.28657 1.93949,0.796669 0.51439,0.510101 0.80337,1.201941 0.80337,1.923331 0,0.72139 -0.28898,1.41323 -0.80337,1.92333 C 106.22511,5.15343 105.52745,5.44 104.8,5.44 c -0.72745,0 -1.42511,-0.28657 -1.93949,-0.79667 -0.51439,-0.5101 -0.80337,-1.20194 -0.80337,-1.92333 0,-0.72139 0.28898,-1.41323 0.80337,-1.923331 C 103.37489,0.28657 104.07255,0 104.8,0 Z m -2.05714,6.8 h 4.11428 c 0.72745,0 1.42511,0.28657 1.9395,0.79667 C 109.31102,8.10677 109.6,8.79861 109.6,9.52 V 17 h -2.05714 v 10.2 h -5.48572 V 17 H 100 V 9.52 c 0,-0.72139 0.28898,-1.41323 0.80337,-1.92333 C 101.31775,7.08657 102.01541,6.8 102.74286,6.8 Z"
					v-bind:class="{
						'player-active': fillMan(3),
					}"
				/>
				<path
					d="m 124.8,0 c 0.72745,0 1.42511,0.28657 1.93949,0.796669 0.51439,0.510101 0.80337,1.201941 0.80337,1.923331 0,0.72139 -0.28898,1.41323 -0.80337,1.92333 C 126.22511,5.15343 125.52745,5.44 124.8,5.44 c -0.72745,0 -1.42511,-0.28657 -1.93949,-0.79667 -0.51439,-0.5101 -0.80337,-1.20194 -0.80337,-1.92333 0,-0.72139 0.28898,-1.41323 0.80337,-1.923331 C 123.37489,0.28657 124.07255,0 124.8,0 Z m -2.05714,6.8 h 4.11428 c 0.72745,0 1.42511,0.28657 1.9395,0.79667 C 129.31102,8.10677 129.6,8.79861 129.6,9.52 V 17 h -2.05714 v 10.2 h -5.48572 V 17 H 120 V 9.52 c 0,-0.72139 0.28898,-1.41323 0.80337,-1.92333 C 121.31775,7.08657 122.01541,6.8 122.74286,6.8 Z"
					v-bind:class="{
						'player-active': fillMan(2),
					}"
				/>
				<path
					d="m 144.8,0 c 0.72745,0 1.42511,0.28657 1.93949,0.796669 0.51439,0.510101 0.80337,1.201941 0.80337,1.923331 0,0.72139 -0.28898,1.41323 -0.80337,1.92333 C 146.22511,5.15343 145.52745,5.44 144.8,5.44 c -0.72745,0 -1.42511,-0.28657 -1.93949,-0.79667 -0.51439,-0.5101 -0.80337,-1.20194 -0.80337,-1.92333 0,-0.72139 0.28898,-1.41323 0.80337,-1.923331 C 143.37489,0.28657 144.07255,0 144.8,0 Z m -2.05714,6.8 h 4.11428 c 0.72745,0 1.42511,0.28657 1.9395,0.79667 C 149.31102,8.10677 149.6,8.79861 149.6,9.52 V 17 h -2.05714 v 10.2 h -5.48572 V 17 H 140 V 9.52 c 0,-0.72139 0.28898,-1.41323 0.80337,-1.92333 C 141.31775,7.08657 142.01541,6.8 142.74286,6.8 Z"
					v-bind:class="{
						'player-active': fillMan(1),
					}"
				/>
			</svg>
		</b-col>
	</b-row>
</template>

<script>
export default {
	props: {
		activePlayersCountFromActiveRoomsGraph: { type: Number, required: false },
		activeRoomNameFromActiveRoomsGraph: { type: String, required: false },
		currentRoomFromActiveRoomsGraph: { required: false },
		displayRoomName: { type: Boolean, required: false },
	},
	methods: {
		fillMan(position) {
			return this.activePlayersCountFromActiveRoomsGraph >= position;
		},
	},
};
</script>

<style scoped>
.men-img {
	width: max(7vw, 50px);
	height: auto;
	fill: #c4c4c4;
}

.player-active {
	fill: black;
}

p {
	font-size: max(18px);
	font-family: "Montserrat";
	color: black;
}

.room-entry {
	height: 4.2vh;
}

.room-name {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@media (min-width: 768px) and (max-width: 991.98px) {
	p {
		font-size: 14px;
	}

	.men-img {
		width: 110px;
	}

	.room-entry {
		height: 3.3vh;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	p {
		font-size: 16px;
	}

	.men-img {
		width: 140px;
	}

	.room-entry {
		height: 3vh;
	}
}

@media (min-width: 1199.98px) and (max-width: 1600px) {
	p {
		font-size: 12px;
		font-family: "Montserrat";
		color: black;
	}
}
</style>
