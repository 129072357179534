export default {
    state: {

    },

    getters: {

    },

    actions: {

    },

    mutations: {

    },
}